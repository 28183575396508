import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>How Switching to Purple Cow Internet Can Transform Internet Access in Nova Scotia  💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
          <StaticImage
          layout="constrained"
          src="images/band-together-inflict-change-together.png"
          alt="How Switching to Purple Cow Internet Can Transform Internet Access in Nova Scotia"
          /> 
        <br></br>
        <br></br>
          <h1>How Switching to Purple Cow Internet Can Transform Internet Access in Nova Scotia</h1>
        
          <p>In an era dominated by large corporations focused on maximizing profits, a small yet mighty challenger is making waves in Nova Scotia's internet landscape: Purple Cow Internet. As a company deeply rooted in the community, we are not just another internet service provider. We are a movement, a statement against the status quo, and a beacon of change in an industry crying out for a revolution.</p>

          <p><strong>Why Purple Cow Stands Out</strong></p>

          <p>Purple Cow Internet is more than just an alternative; it's a statement. We provide the same high-quality internet as our competitors but at half the cost. Why pay more for the same service? Our mission is clear: to drive down the overall cost of internet in the Maritimes, making it accessible and affordable for everyone.</p>

          <p>Our commitment to customer service sets us apart. As a local company, we understand the needs of Nova Scotians. We're not just another faceless corporation; we're your neighbors, dedicated to offering reliable internet at an incredible price. In a time when many companies prioritize their bottom line over customer satisfaction, Purple Cow is different. We're focused on you.</p>

          <p><strong>Our Impact and Your Role</strong></p>

          <p>This past year, thanks to our herd—the loyal customers who believe in our mission—we've grown by 30%, making us one of the fastest-growing internet service providers in Canada. But our journey is far from over. The more people join the herd, the stronger our message: it's not okay for large providers to overcharge and underdeliver.</p>

          <p>Our recent expansions into TV and Home Phone services, alongside significantly reducing customer service wait times, are just the beginning. We have ambitious plans to reshape the internet industry in Nova Scotia, driving down costs and improving service quality. But we can't do it alone.</p>

          <p><strong>A Call to Action</strong></p>

          <p>When you switch to Purple Cow, you're not just choosing an internet service provider. You're casting a vote for change. You're standing up to the giants, declaring that the status quo is no longer acceptable. Last week, during the CRTC hearings, a president of one of Nova Scotia's major telcos expressed concern that Purple Cow could take up to 30-40% of their customers. This isn't just a possibility; it's a promise of what's to come.</p>

          <p>We are poised to make internet access in Nova Scotia not just better, but equitable. By joining the herd, you help us grow stronger, driving down prices and improving service for everyone. This is your chance to be part of something bigger. A chance to ensure that the internet in Nova Scotia is fair, affordable, and accessible.</p>

          <p><strong>Make the Switch. Make a Difference.</strong></p>

          <p>Switching to Purple Cow Internet isn't just a smart financial decision—it's a stand against exorbitant prices and poor service. It's a commitment to supporting a local business that cares. Help us transform the internet landscape in Nova Scotia. Together, we can ensure a future where everyone has access to affordable, high-quality internet service.</p>

          <p>Jump the fence and join the herd.</p>
          
          <p>
            Bradley Farquhar<br></br>
            Founder & CEO<br></br>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
